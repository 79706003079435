<script setup lang="ts">
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

const { order } = defineProps<Props>()

const { t, d } = useI18n()

defineOptions({
  name: 'OrderConfirmationOrderOverviewVoucher',
})
</script>

<template>
  <OrderConfirmationOrderOverviewWrapper
    :order
    :show-presale-disclaimer="false"
  >
    <div class="grid grid-cols-3 py-2 print:text-black">
      <span v-if="cinema" v-text="t('cinema')" />
      <span
        v-if="cinema"
        class="col-span-2"
        v-text="formatCinemaAddressFromOrder(order)"
      />

      <span v-text="t('orderNumber')" />
      <span class="col-span-2" v-text="order.orderNumber" />

      <span v-text="t('vouchers')" />
      <div class="col-span-2 flex flex-col">
        <span
          v-for="(item, index) in order.vouchers"
          :key="index"
          v-text="`${item.quantity}x ${item.name}`"
        />
      </div>

      <span v-text="t('validUntil')" />
      <span
        class="col-span-2"
        v-text="
          d(order.vouchers[0].validUntil, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          })
        "
      />
    </div>
  </OrderConfirmationOrderOverviewWrapper>
</template>

<i18n>
de:
  cinema: "Kino"
  orderNumber: "Bestellnummer"
  vouchers: "Gutscheine"
  validUntil: "Gültig bis"
es:
  cinema: "Cine"
  orderNumber: "Número de pedido"
  vouchers: "Vales"
  validUntil: "Válido hasta"
en:
  cinema: "Cinema"
  vouchers: "Vouchers"
  validUntil: "Valid until"
</i18n>

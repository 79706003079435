<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
  showPresaleDisclaimer?: boolean
}

const { order, showPresaleDisclaimer = true } = defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()

defineOptions({
  name: 'OrderConfirmationOrderOverviewWrapper',
})
</script>

<template>
  <section class="mt-11 print:text-black">
    <div class="flex flex-row items-end justify-between pb-1">
      <h2
        class="heading-lg text-balance leading-none print:!text-black print:dark:!text-black"
        v-html="ct('THANKYOU_H2_1', null, t('title'))"
      />
      <ButtonPrint />
    </div>

    <Divider />

    <slot />

    <Divider />

    <OrderConfirmationOrderOverviewPrice :show-presale-disclaimer :order />
  </section>
</template>

<i18n>
de:
  title: "Bestellübersicht"
es:
  title: "Resumen"
en:
  title: "Order overview"
</i18n>

<script setup lang="ts">
import type {
  Maybe,
  OrderConcession,
  ConcessionSubItem,
  OrderConcessionSubItem,
} from '#gql/default'
import ScannableCode from '@base/components/scannable-code.vue'

interface Props {
  concession: OrderConcession
}

const { concession } = defineProps<Props>()

const concessionString = computed(() => {
  return `${concession.quantity}x ${concession.concession.name} ${concessionDescription.value ? `(${concessionDescription.value})` : ''}`
})

const concessionDescription = computed(() => {
  if (!concession.subItemSelection || !concession.subItemSelection.length) {
    return null
  }

  const temp = concession?.concession?.subItems?.map(
    (item: Maybe<ConcessionSubItem>) => {
      const selectedSubItem = concession?.subItemSelection?.find(
        (subItem: OrderConcessionSubItem) => subItem?.subItem?.id === item?.id,
      )

      return selectedSubItem?.option?.name ?? item?.name
    },
  )

  if (temp.length <= 0) {
    return null
  }

  return temp.join(', ')
})

defineOptions({
  name: 'OrderConcession',
})
</script>

<template>
  <section class="flex flex-col">
    <ScannableCode v-if="concession.codes?.[0]" :code="concession.codes[0]" />

    <span class="print:!text-black" v-text="concessionString" />
  </section>
</template>

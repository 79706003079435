<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()

const isFreeSeating = computed(() => {
  return order.tickets.every((ticket) => ticket.seat === null)
})

defineOptions({
  name: 'OrderConfirmationConcessions',
})
</script>

<template>
  <div class="flex flex-col space-y-4 py-4" v-if="order.concessions.length">
    <div class="flex flex-col">
      <h2
        class="heading-lg text-balance pb-1 leading-none print:!text-black print:dark:!text-black"
        v-text="order.overview?.concessionHeadline ?? t('headline')"
      />

      <Divider />

      <span
        v-if="isFreeSeating"
        class="text-lg print:!text-black"
        v-html="ct('MAIL_BOOKING_CONCESSIONS_INFO_NOT_SEATED', null, '')"
      />

      <span
        v-else
        class="text-lg print:!text-black"
        v-html="
          ct(
            'MAIL_BOOKING_CONCESSIONS_INFO_SEATED',
            null,
            order.overview?.concessionInfo ?? '',
          )
        "
      />
    </div>

    <div class="flex flex-col items-start space-y-4">
      <OrderConcession
        :concession="concession"
        v-for="(concession, index) in order.concessions"
        :key="index"
      />
    </div>
  </div>
</template>

<i18n>
de:
  headline: 'Mitgebuchte Menüs'
es:
  headline: 'Menús compartidos'
en:
  headline: 'Menus'
</i18n>

<script setup lang="ts">
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

const { order, purchaseData } = defineProps<Props>()

const { t, locale } = useI18n()

const showFlagRatingString = computed(() => {
  let temp = []

  if (Array.isArray(order.show?.flags) && order.show.flags.length) {
    temp.push(...order.show.flags.map((flag: { name: string }) => flag.name))
  }

  if (order.show?.movie?.contentRating?.minimumAge) {
    temp.push(`FSK ${order.show.movie.contentRating.minimumAge}`)
  }

  if (temp.length) {
    temp.unshift('')
  }

  return temp.join(' | ')
})

const dateTimeString = computed(() => {
  const date = new Date(order.show!.beginning)
  return `${date.toLocaleDateString(locale.value, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })} ${date.toLocaleTimeString(locale.value, {
    hour: '2-digit',
    minute: '2-digit',
  })}`
})

defineOptions({
  name: 'OrderConfirmationOrderOverviewTicket',
})
</script>

<template>
  <OrderConfirmationOrderOverviewWrapper
    :order
    :show-presale-disclaimer="true"
    class="print:text-black print:dark:text-black"
  >
    <div v-if="order.cinema" class="grid grid-cols-3 gap-x-4 py-2">
      <span v-text="t('cinema')" />
      <div class="col-span-2">
        <span
          class="text-primary print:text-black"
          v-text="order.cinema.name"
        />
        <span v-text="`, ${getFormattedCinemaAddress(order.cinema)}`" />
      </div>

      <span v-if="order.show" v-text="t('show')" />
      <div v-if="order.show" class="col-span-2">
        <span v-text="order.show.name" class="text-primary print:text-black" />
        <span
          class="text-light print:text-black print:dark:text-black"
          v-text="showFlagRatingString"
        />
      </div>

      <span v-text="t('dateTime')" />
      <span
        class="text-light col-span-2 print:text-black print:dark:text-black"
        v-text="dateTimeString"
      />

      <span v-if="order.show" v-text="t('auditorium')" />
      <span
        v-if="order.show"
        class="text-light col-span-2 print:text-black print:dark:text-black"
        v-text="order.show.auditorium?.name"
      />

      <span v-if="order.overview?.seats" v-text="t('seats')" />
      <span
        v-if="order.overview?.seats"
        class="col-span-2"
        v-text="order.overview.seats"
      />

      <span v-if="order.overview?.seatCategories" v-text="t('seatCategory')" />
      <span
        v-if="order.overview?.seatCategories"
        class="col-span-2"
        v-text="order.overview.seatCategories"
      />

      <span v-text="t('orderNumber')" />
      <span class="col-span-2" v-text="purchaseData.id" />
    </div>
  </OrderConfirmationOrderOverviewWrapper>
</template>

<i18n>
de:
  row: "Reihe"
  seat: "Sitz"
  cinema: "Kino"
  show: "Vorstellung"
  dateTime: "Datum, Uhrzeit"
  seats: "Sitzplätze"
  seatCategory: "Sitzkategorie"
  orderNumber: "Bestellnummer"
  auditorium: "Saal"
es:
  row: "Fila"
  seat: "Asiento"
  cinema: "Cine"
  show: "Función"
  dateTime: "Fecha/Hora"
  seats: "Asientos"
  seatCategory: "Categoría de asiento"
  orderNumber: "Número de pedido"
  auditorium: "Sala"
en:
  cinema: "Cinema"
  show: "Show"
  dateTime: "Date, time"
  seats: "Seats"
  seatCategory: "Seat category"
  orderNumber: "Order number"
  auditorium: "Auditorium"
</i18n>

<script setup lang="ts">
import type { Code } from '#gql/default'

interface Props {
  code: Code & { __typename?: 'QrCode' | 'Barcode' }
  showCode?: boolean
  alt?: string
}

const { showCode = false } = defineProps<Props>()

const { t } = useI18n()

defineOptions({
  name: 'ScannableCode',
})
</script>

<template>
  <QrCode
    v-if="code?.__typename === 'QrCode'"
    :image="code.image"
    :code="code.code"
    :show-code
    :alt="alt ?? t('qrCode')"
  />
  <BarCode
    v-else-if="code?.__typename === 'Barcode'"
    :image="code.image"
    :code="code.code"
    :alt="alt ?? t('barCode')"
  />

  <NuxtImg
    v-else
    :src="code.image"
    class="bg-light dark:bg-light mb-2 aspect-auto h-16 p-1"
  />
</template>

<i18n>
de:
  qrCode: "QR-Code"
  barCode: "Barcode"
en:
  qrCode: "QR-Code"
  barCode: "Barcode"
es:
  qrCode: "Código QR"
  barCode: "Código de barras"
</i18n>

<script setup lang="ts">
import type { Order } from '#gql/default'
import type { PurchaseData } from '@account/types/order'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()

defineOptions({
  name: 'OrderConfirmationTypeCardCharging',
})
</script>

<template>
  <div>
    <OrderConfirmationHeading :order />
    <OrderConfirmationOrderOverviewCustomerCard :order :purchase-data />

    <p class="font-bold" v-text="ct('THANKYOU_H3', null, t('haveFunText'))" />

    <OrderConfirmationBackButton v-if="order.cinema" :cinema="order.cinema" />
  </div>
</template>

<i18n>
de:
  haveFunText: "Viel Spaß beim nächsten Kinobesuch."
es:
  haveFunText: "¡Disfruta de tu próxima visita al cine!"
en:
  haveFunText: "Have fun at your next cinema visit!"
</i18n>

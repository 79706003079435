<script setup lang="ts">
import type { Cinema } from '#gql/default'
import { ROUTE_NAMES } from '@portal/constants/routes'

interface Props {
  cinema: Cinema
}

defineProps<Props>()

const { t } = useI18n()
const localeRoute = useLocaleRoute()
const { icons } = useDesign()

defineOptions({
  name: 'OrderConfirmationBackButton',
})
</script>

<template>
  <NuxtLink
    v-if="cinema.city?.urlSlug && cinema.urlSlug"
    class="flex flex-row items-center underline"
    :to="
      localeRoute({
        name: ROUTE_NAMES.CINEMA_PROGRAM,
        params: {
          citySlug: cinema.city.urlSlug,
          cinemaSlug: cinema.urlSlug,
        },
      })
    "
  >
    <Icon
      :name="icons.CHEVRON_LEFT"
      class="size-5 fill-black dark:fill-white"
    />
    <span v-text="t('backToCinemaProgram', { cinemaName: cinema.name })" />
  </NuxtLink>
</template>

<i18n>
de:
  backToCinemaProgram: "Zurück zur Programmauswahl vom {cinemaName}!"
es:
  backToCinemaProgram: "Volver a la selección de programas de {cinemaName}!"
en:
  backToCinemaProgram: "Back to the cinema program selection of {cinemaName}!"
</i18n>

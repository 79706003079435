<script setup lang="ts">
import type { Order } from '#gql/default'
import isOrderCancelled from '@account/utils/is-order-cancelled'

interface Props {
  order: Order
}

defineProps<Props>()

const { t } = useI18n()

defineOptions({
  name: 'OrderConfirmationCodes',
})
</script>

<template>
  <section
    v-if="isOrderCancelled(order)"
    class="flex h-64 w-full items-center justify-center text-center"
  >
    <span class="text-xl font-bold" v-text="`- ${t('cancelled')} -`" />
  </section>
  <section v-else class="mt-11 flex flex-col">
    <h2
      v-if="order.overview?.headline"
      class="heading-lg text-balance pb-1 leading-none print:!text-black print:dark:!text-black"
      v-text="order.overview?.headline"
    />

    <Divider />

    <div v-if="order.singleTicketCodes?.length" class="flex flex-col">
      <div class="my-2 flex flex-col space-y-2">
        <div
          class="flex flex-col items-start space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0"
        >
          <OrderConfirmationTicketCodes :codes="order.singleTicketCodes" />
        </div>

        <div class="flex flex-col items-start space-y-1.5 print:hidden h-auto">
          <NuxtLink
            v-if="order.walletLinks?.pkpass"
            :to="order.walletLinks.pkpass"
            :aria-label="t('linkToAppleWallet')"
          >
            <IconAppleWallet />
          </NuxtLink>
          <NuxtLink
            v-if="order.walletLinks?.gpass"
            :to="order.walletLinks.gpass"
            :aria-label="t('linkToGoogleWallet')"
          >
            <IconGoogleWallet />
          </NuxtLink>
        </div>
      </div>
    </div>

    <div>
      <OrderConfirmationCodesTickets :order />
    </div>
  </section>
</template>

<i18n>
de:
  cancelled: "Diese Buchung wurde storniert"
  linkToAppleWallet: "Link zur Apple Wallet"
  linkToGoogleWallet: "Link zur Google Wallet"
es:
  cancelled: "Esta reserva ha sido cancelada"
  linkToAppleWallet: "Link a la Apple Wallet"
  linkToGoogleWallet: "Link a la Google Wallet"
en:
  cancelled: "This booking has been cancelled"
  linkToAppleWallet: "Link to Apple Wallet"
  linkToGoogleWallet: "Link to Google Wallet"
</i18n>

<style>
.st0 {
  fill: #a6a6a6;
}
.st1 {
  clip-path: url(#SVGID_00000036241015773612366540000011328654630077282433_);
}
.st2 {
  fill: #dedbce;
}
.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #40a5d9;
}
.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffb003;
}
.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #40c740;
}
.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f26d5f;
}
.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #d9d6cc;
}
.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #dedbce;
}
.st9 {
  fill: #ffffff;
}
</style>

<script setup lang="ts">
import type { Ga4Product } from '@account/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: {
    id: string
    affiliation: string
    revenue: number
    coupon: string
    items: Ga4Product[]
  }
}

const { order, purchaseData } = defineProps<Props>()

const { t } = useI18n()

defineOptions({
  name: 'OrderConfirmationTypeVoucher',
})
</script>

<template>
  <div>
    <OrderConfirmationHeading :order />
    <OrderConfirmationOrderOverviewVoucher :order :purchase-data />
    <p
      class="mt-8 font-bold print:text-black"
      v-text="t('haveFunAtNextVisit')"
    />

    <OrderConfirmationBackButton
      v-if="order.cinema"
      class="mt-4"
      :cinema="order.cinema"
    />
  </div>
</template>

<i18n>
de:
  haveFunAtNextVisit: "Viel Spaß beim nächsten Kinobesuch!"
es:
  haveFunAtNextVisit: "¡Disfruta de tu próxima visita al cine!"
en:
  haveFunAtNextVisit: "Have fun at your next cinema visit!"
</i18n>

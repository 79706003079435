<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()

const personalizationText = computed(() => {
  if (!order.personalisedFor || order.personalisedFor.length === 0) {
    return null
  }

  return `${t('personalizationLabel')} <strong>${order.personalisedFor.join(', ')}</strong>`
})

defineOptions({
  name: 'OrderConfirmationPersonalization',
})
</script>

<template>
  <span v-if="personalizationText" v-html="personalizationText" />
</template>

<i18n>
de:
  personalizationLabel: 'Diese Buchung ist personalisiert für:'
en:
  personalizationLabel: 'This booking is personalized for:'
es:
  personalizationLabel: 'Esta reserva está personalizada para:'
</i18n>

<template>
  <div data-catalog-movie-list>
    <Suspense>
      <div>
        <template v-if="pending">
          <LazyCarouselSkeleton v-if="layout === CAROUSEL" />
          <LazyMovieList v-else-if="layout === MOVIE_LIST" :render-skeletons />
          <LazyMovieCardsSkeleton
            v-else
            :layout="movieCardsLayout"
            :render-skeletons
          />
        </template>

        <template v-else-if="!pending">
          <LazyMovieCarousel
            v-if="layout === CAROUSEL"
            :movies
            :infinite
            :slide-duration
            :autoplay
            :link-params
            :render-above-the-fold
          />
          <LazyMovieList
            v-else-if="layout === MOVIE_LIST"
            :movies
            :hide-date
            :hide-link
            :link-params
            :render-above-the-fold
          />
          <LazyMovieCardsHorizontal
            v-else-if="layout === HORIZONTAL"
            :movies
            :render-above-the-fold
            :link-params
            :hide-link
          />
          <LazyMovieCardsVertical
            v-else
            :movies
            :render-above-the-fold
            :link-params
            :hide-link
          />
        </template>
      </div>
      <template #fallback>
        <LazyCarouselSkeleton v-if="layout === CAROUSEL" />
        <LazyMovieList v-else-if="layout === MOVIE_LIST" :render-skeletons />
        <LazyMovieCardsSkeleton
          v-else
          :layout="movieCardsLayout"
          :render-skeletons
        />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import type { LinkParam } from '#gql/default'

interface Props {
  renderAboveTheFold?: boolean
  layout: MovieListLayout
  itemsPerPage?: number
  movieListId: string
  autoplay?: boolean
  linkParams?: LinkParam[]
  hideLink?: boolean
  hideDate?: boolean
  infinite?: boolean
  preserveFilters?: boolean
  slideDuration?: number
  urlPrefix?: string
}

const { MOVIE_LIST, HORIZONTAL, VERTICAL, CAROUSEL } = MOVIE_LIST_LAYOUT

const {
  layout,
  movieListId,
  itemsPerPage = 10,
  renderAboveTheFold = false,
  slideDuration = 5000,
  linkParams = [],
  urlPrefix = ' ',
} = defineProps<Props>()

const fetchOperation = computed(() =>
  layout === CAROUSEL
    ? 'FetchMovieListForMovieCarousel'
    : layout === MOVIE_LIST
      ? 'FetchMovieListForMovieList'
      : 'FetchMovieListForMovieCards',
)

const { pending, movies } = await useMovieList({
  fetchOperation: fetchOperation.value,
  variables: {
    id: movieListId,
    limit: itemsPerPage,
  },
})

const renderSkeletons = computed(() => itemsPerPage ?? 4)
const movieCardsLayout = computed(() =>
  layout === HORIZONTAL ? HORIZONTAL : VERTICAL,
)

defineOptions({
  name: 'CatalogMovieList',
})
</script>

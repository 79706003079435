<template>
  <div v-for="(n, i) in Array(shows).fill(0)" :key="`show-${n}-${i}`">
    <span
      v-if="shows > 1"
      class="text-lg font-bold print:text-black print:dark:text-black"
      v-text="`${t('show')} #${i + 1} `"
    />
    <div
      class="grid grid-cols-1 gap-8 py-3 md:grid-cols-2 md:gap-4 print:grid-cols-3"
      :class="{
        'lg:grid-cols-3': ticketsByShow[i]?.length > 2,
      }"
    >
      <OrderConfirmationCodesTicket
        v-for="(ticket, index) in ticketsByShow[i]"
        :key="`ticket-${index}`"
        :ticket
        :overview="order.overview!"
        :isFreeSeat
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()

const isFreeSeat = computed(() => {
  return order.tickets.every((ticket) => {
    return (
      !ticket.seat || (ticket.seat.row === '0' && ticket.seat.number === '0')
    )
  })
})

const shows = computed(() => {
  if (isFreeSeat.value) {
    return 1
  }

  const tickets = order.tickets

  if (!tickets?.length) {
    return 0
  }

  const seatMap = new Map<string, number>()

  tickets.forEach((ticket) => {
    const key = `${ticket?.seat?.row}-${ticket?.seat?.number}`
    seatMap.set(key, (seatMap.get(key) || 0) + 1)
  })

  return Math.max(...Array.from(seatMap.values()))
})

const ticketsByShow = computed(() => {
  const tickets = order.tickets

  if (!tickets?.length) {
    return []
  }

  if (shows.value === 1) {
    return [tickets]
  }

  const uniqueSeats = new Set(
    tickets.map(
      (ticket: Order['tickets']) =>
        `${ticket?.seat?.row}-${ticket?.seat?.number}`,
    ),
  )

  return Array.from({ length: shows.value }, (_, showIndex) =>
    tickets.filter(
      (_: Order['tickets'][number], ticketIndex: number) =>
        Math.floor(ticketIndex / uniqueSeats.size) === showIndex,
    ),
  )
})

defineOptions({
  name: 'OrderConfirmationCodesTickets',
})
</script>

<i18n>
  de:
    show: "Vorstellung"
  es:
    show: "Función"
  en:
    show: "Show"
  </i18n>

import type { I18n } from 'vue-i18n'

export default function useCinemaStrings() {
  const cinemaStrings = useCinemaStringsState()
  const nuxtApp = useNuxtApp()

  async function fetchCinemaStrings({
    cinemaId,
    keys,
  }: {
    cinemaId?: string
    keys: string[]
  }) {
    const $i18n = nuxtApp.$i18n as I18n & { locale: Ref<string> }

    useGqlHeaders({
      'Accept-Language': $i18n.locale.value,
    })

    try {
      const result = await GqlFetchCinemaStrings({ cinemaId, keys })

      if (result.cinemaStrings) {
        cinemaStrings.value = {
          ...cinemaStrings.value,
          ...result.cinemaStrings.reduce(
            (
              acc: { [key: string]: string },
              { key, value }: { key: string; value: string },
            ) => {
              acc[key] = value
              return acc
            },
            {},
          ),
        }
      }
    } catch {
      cinemaStrings.value = {}
    }
  }

  function ct(
    key: string,
    placeholders?: { [key: string]: string } | null,
    fallback?: string,
  ): string {
    let string = (
      cinemaStrings.value as unknown as {
        [key: string]: string
      }
    )[key]

    if (!string) {
      return fallback ?? key
    }

    string = decodeURIComponent(string)

    if (placeholders) {
      for (const key in placeholders) {
        const regex = new RegExp('\\{' + key + '\\}', 'g')
        string = string.replace(regex, placeholders[key])
      }
    }
    return string
  }

  return {
    cinemaStrings,
    fetchCinemaStrings,
    ct,
  }
}

<script setup lang="ts">
import type { Order } from '#gql/default'
import { LINK_TARGET } from '@base/constants/link'
import isOrderCancelled from '@account/utils/is-order-cancelled'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()

const isCancellationPossible = computed(() => {
  return (
    order?.cancellationInfo?.policy === 'ALLOWED' &&
    order?.cancellationInfo?.link?.length
  )
})

const cancellationText = computed(() => {
  return isCancellationPossible.value
    ? t('cancellationText')
    : t('cancellationNotPossible')
})

defineOptions({
  name: 'OrderConfirmationCancellation',
})
</script>

<template>
  <section v-if="!isOrderCancelled(order)" class="flex flex-col">
    <span
      class="print:!text-black print:dark:!text-black"
      v-text="cancellationText"
    />

    <NuxtLink
      v-if="isCancellationPossible"
      class="text-light hover:text-primary mb-4 underline print:!text-black print:dark:!text-black"
      :to="order.cancellationInfo?.link"
      :target="LINK_TARGET.BLANK"
    >
      {{ order.cancellationInfo?.link }}
    </NuxtLink>
  </section>
</template>

<i18n>
de:
  cancellationText: "Eine Stornierung oder Umbuchung der Bestellung ist bis zu 60 Minuten vor Vorstellungsbeginn (nur) über folgenden Link möglich: "
  cancellationNotPossible: "Für diese Vorstellung sind keine Stornierungen oder Umbuchungen möglich!"
es:
  cancellationText: "Una cancelación o cambio de la orden es posible hasta 60 minutos antes del comienzo de la función (solo) a través del siguiente enlace: "
  cancellationNotPossible: "Para esta funcionalidad no hay cancelaciones ni cambios!"
en:
  cancellationText: "A cancellation or change of the order is possible up to 60 minutes before the start of the show (only) via the following link: "
  cancellationNotPossible: "Cancellations and/or seat changes are not possible for this show!"
</i18n>

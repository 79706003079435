<template>
  <OrderConfirmationCustomNote ct-key="THANKYOU_BOOKINGNOTE" />
  <OrderConfirmationCustomNote
    v-for="key in ticketOrderFlagKeys"
    :key
    :ct-key="key"
  />
  <OrderConfirmationCustomNote ct-key="MAIL_BOOKING_CUSTOMNOTICE" />
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { ticketOrderFlagKeys } = await useOrderCinemaStringsFetch(order)

defineOptions({
  name: 'OrderConfirmationCustomNotes',
})
</script>

<script setup lang="ts">
const { t } = useI18n()
const { icons } = useDesign()

function print() {
  window.print()
}

defineOptions({
  name: 'ButtonPrint',
})
</script>

<template>
  <button
    class="button button-sm bg-primary text-on-primary hover:dark:bg-primary-dark-1 hover:bg-primary-dark-1 border-primary hover:border-primary-dark-1 hover:dark:border-primary-dark-1 w-auto border print:hidden"
    @click="print"
  >
    <Icon :name="icons.PRINTER" />
    <span v-text="t('print')" />
  </button>
</template>

<i18n>
de:
  print: "Drucken"
es:
  print: "Imprimir"
</i18n>

// Note: We need the import here because the auto import does not work in the app.config.ts
import { IMAGE_FIT, IMAGE_LOADING } from '@base/constants/image'
import type { ImageModifier } from '#gql/default'

interface MovieListIds {
  HIGHLIGHTS: string
  NOW_PLAYING: string
  COMING_SOON: string
}

const movieListIds: MovieListIds = {
  HIGHLIGHTS: '9',
  NOW_PLAYING: '2',
  COMING_SOON: '97',
}

const imageConfigDefaults = {
  preload: false,
  loading: IMAGE_LOADING.LAZY,
}

const images: ImageConfigs = {
  voucher: {
    card: {
      width: 600,
      height: 450,
      loading: IMAGE_LOADING.EAGER,
      fit: IMAGE_FIT.FIT,
    },
    designOption: {
      width: 290,
      height: 218,
      loading: IMAGE_LOADING.EAGER,
      fit: IMAGE_FIT.FIT,
    },
  },
  raffle: {
    prize: {
      width: 600,
      height: 300,
      loading: IMAGE_LOADING.LAZY,
      fit: IMAGE_FIT.INSIDE,
    },
  },
  person: {
    card: {
      width: 200,
      height: 300,
      loading: IMAGE_LOADING.LAZY,
      fit: IMAGE_FIT.CROP,
    },
  },
  cinemaCard: {
    logo: {
      width: 200,
      height: 200,
      loading: IMAGE_LOADING.LAZY,
      fit: IMAGE_FIT.CROP,
    },
  },
  gallery: {
    vertical: {
      ...imageConfigDefaults,
      fit: IMAGE_FIT.OUTSIDE,
    },
    horizontal: {
      ...imageConfigDefaults,
      fit: IMAGE_FIT.OUTSIDE,
    },
    carousel: {
      ...imageConfigDefaults,
      width: 390,
      height: 195,
      fit: IMAGE_FIT.CROP,
      sizes: 'xs:100vw sm:100vw md:100vw lg:100vw',
      quality: 70,
    },
  },
  moviePage: {
    poster: {
      ...imageConfigDefaults,
      preload: true,
      width: 112,
      height: 168,
      fit: IMAGE_FIT.FIT,
      loading: IMAGE_LOADING.EAGER,
      sizes: 'sm:112px md:176px lg:176px xl:176px',
    },
    hero: {
      ...imageConfigDefaults,
      preload: true,
      width: 1200,
      height: 600,
      fit: IMAGE_FIT.FIT,
      loading: IMAGE_LOADING.EAGER,
    },
    mediaGallery: {
      ...imageConfigDefaults,
      width: 220,
      height: 110,
      fit: IMAGE_FIT.FIT,
    },
    castGallery: {
      ...imageConfigDefaults,
      width: 110,
      height: 165,
      fit: IMAGE_FIT.FIT,
    },
  },
  movie: {
    grid: {
      ...imageConfigDefaults,
      width: 112,
      height: 168,
      fit: IMAGE_FIT.STRETCH,
      sizes: 'sm:112px md:176px lg:176px xl:176px',
    },
  },
  cms: {
    imageBlock: {
      ...imageConfigDefaults,
      fit: IMAGE_FIT.CROP,
    },
  },
  showGroup: {
    gallery: {
      width: 800,
      height: 400,
      loading: IMAGE_LOADING.LAZY,
      preload: false,
      fit: IMAGE_FIT.CROP,
      sizes: 'xs:100vw sm:100vw md:100vw lg:100vw',
    },
    cinemaLogo: {
      width: 104,
      height: 104,
      loading: IMAGE_LOADING.LAZY,
      preload: false,
      fit: IMAGE_FIT.CROP,
    },
    poster: {
      width: 200,
      height: 400,
      loading: IMAGE_LOADING.LAZY,
      preload: false,
      fit: IMAGE_FIT.FIT,
      sizes: 'xs:200px sm:400px md:400px lg:400px',
    },
  },
}

const fullWidthHeroBlocks = true
const cinemaHeroImageModifiers: ImageModifier[] = []
const movieHeroImageModifiers: ImageModifier[] = []
const legacyShowWidgetUrl =
  'kino/{cityUrlSlug}/{cinemaUrlSlug}/show/{showUrlSlug}'

export default defineAppConfig({
  affiliateFallback: undefined,
  images,
  enableWidgetMode: false,
  enableWatchList: true,
  enableGooglePublisherTag: false,
  enableCinemaBannersInWidgetMode: true,
  movieListIds,
  adSlots: undefined,
  fullWidthHeroBlocks,
  cinemaHeroImageModifiers,
  movieHeroImageModifiers,
  roktAccountId: '',
  legacyShowWidgetUrl,
})

<template>
  <div>
    <div v-if="errorMessage" class="relative h-12">
      <AuthErrorMessage :error="errorMessage" />
    </div>

    <FormKit
      v-model="formData"
      type="form"
      :actions="false"
      novalidate
      @submit="submit"
    >
      <FormKitMessages class="text-system-error mb-4" />

      <div class="flex flex-col space-y-4">
        <FormKit
          type="email"
          name="email"
          autocomplete="email"
          :floating-label="true"
          :label="t('emailAddress')"
          validation="required|email"
          :validation-messages="{
            required: t('requiredEmail'),
          }"
        />

        <span v-text="t('registerHint')" />

        <FormKit
          type="password"
          name="password"
          autocomplete="new-password"
          :label="t('password')"
          :floating-label="true"
          validation="required:trim|contains_numeric|contains_uppercase|contains_lowercase|contains_alphanumeric|length:6,32"
          :validation-label="t('password')"
          :validation-messages="{
            required: t('requiredPassword'),
            contains_numeric: t('genericPasswordMessage'),
            contains_uppercase: t('genericPasswordMessage'),
            contains_lowercase: t('genericPasswordMessage'),
            contains_alphanumeric: t('genericPasswordMessage'),
            length: t('genericPasswordMessage'),
          }"
        />

        <FormKit
          type="password"
          name="password_confirm"
          autocomplete="new-password"
          :label="t('passwordConfirmation')"
          :floating-label="true"
          validation="required|confirm"
          validation-label="Password confirmation"
          :validation-messages="{
            required: t('requiredPasswordConfirm'),
            confirm: t('confirmPasswordConfirm'),
          }"
        />

        <FormKit
          type="checkbox"
          :label="t('agb')"
          :help="t('agbHint')"
          validation="accepted|required"
          :validation-messages="{
            accepted: t('requiredAgb'),
            required: t('requiredAgb'),
          }"
        />

        <div class="mt-4 flex flex-col space-y-1">
          <KButton :text="t('register')" type="submit" :theme="PRIMARY" />

          <KButton
            :text="t('allReadyAccount')"
            :theme="TRANSPARENT"
            @click="changeState(LOGIN)"
          />
        </div>
      </div>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { changeState } = useAuthState()

const { REGISTER_ACCOUNT_CREATED, LOGIN } = USER_AUTH_STATE
const { TRANSPARENT, PRIMARY } = BUTTON_THEME

const formData = ref()
const errorMessage = ref()

async function submit() {
  const { data, error } = await useAsyncGql('AccountCreate', {
    email: formData.value?.email,
    password: formData.value.password,
  })

  if (data.value?.accountCreate?.user) {
    changeState(REGISTER_ACCOUNT_CREATED)
  }

  if (error.value) {
    errorMessage.value =
      error.value?.cause?.gqlErrors?.[0]?.extensions?.message ??
      error.value?.cause?.gqlErrors?.[0]?.message
  }
}

defineOptions({
  name: 'AuthRegister',
})
</script>

<i18n>
de:
  register: "Registrieren"
  registerHint: "Das Passwort muss aus mindestens 6 Zeichen bestehen und mindestens einen Klein- und Großbuchstaben, sowie eine Zahl enthalten."
  allReadyAccount: "Bereits ein Konto?"
  emailAddress: "E-Mail-Adresse"
  password: "Passwort"
  passwordConfirmation: "Passwort wiederholen"
  agb: "Hiermit akzeptiere ich die AGB und Datenschutzbestimmungen von kinoheld."
  agbHint: "Hinweis: Dieser Onlineshop wird von der kinoheld GmbH betrieben. Alle Daten werden sicher, via TLS verschlüsselt übermittelt. Siehe Sicherheit."
  requiredAgb: 'Bitte AGB akzeptieren.'
  requiredEmail: 'Bitte E-Mail-Adresse eingeben.'
  requiredPassword: 'Bitte Passwort eingeben.'
  genericPasswordMessage: 'Das Passwort entspricht nicht unseren Richtlinien (siehe oben).'
  requiredPasswordConfirm: 'Bitte Passwort wiederholen.'
  confirmPasswordConfirm: 'Passwörter stimmen nicht überein.'
es:
  register: "Registrarse"
  registerHint: "La contraseña debe tener al menos 6 caracteres y contener al menos una letra mayúscula y minúscula, así como un número."
  allReadyAccount: "¿Ya tienes una cuenta?"
  emailAddress: "Correo electrónico"
  password: "Contraseña"
  passwordConfirmation: "Repetir contraseña"
  agb: "Acepto los términos y condiciones y la política de privacidad de kinoheld."
  agbHint: "Nota: Esta tienda en línea es operada por kinoheld GmbH. Todos los datos se transmiten de forma segura, cifrados mediante TLS. Ver seguridad."
  requiredAgb: 'Por favor, acepta los términos y condiciones.'
  requiredEmail: 'Por favor, introduce tu dirección de correo electrónico.'
  requiredPassword: 'Por favor, introduce tu contraseña.'
  genericPasswordMessage: 'La contraseña no cumple con nuestros criterios (ver arriba).'
  requiredPasswordConfirm: 'Por favor, repite tu contraseña.'
  confirmPasswordConfirm: 'Las contraseñas no coinciden.'
</i18n>

<script setup lang="ts">
import type { Order } from '#gql/default'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()

defineOptions({
  name: 'OrderConfirmationShopItems',
})
</script>

<template>
  <div>
    <PageHeading :title="t('article')" :tag="PAGE_HEADING_TAG.H2" />
    <Divider />
    <div class="flex flex-col py-2 print:text-black">
      <div
        v-if="order.singleTicketCodes?.length"
        class="ml-0 mr-auto flex flex-col gap-2"
      >
        <div v-if="order.pickupCode">
          <span v-text="`${t('pickupCode')}: `" />
          <span class="text-lg font-bold" v-text="order.pickupCode" />
        </div>
        <ScannableCode
          v-for="code in order.singleTicketCodes"
          :key="code.code"
          :code="code"
        />
      </div>
      <OrderConcession
        v-for="(concession, index) in order.shopItems"
        :key="index"
        :concession="concession"
      />
    </div>
  </div>
</template>

<i18n>
de:
  article: "Artikel"
  pickupCode: "Abholnummer"
es:
  article: "Artículo"
  pickupCode: "Código de recogida"
en:
  article: "Article"
  pickupCode: "Pickup code"
</i18n>

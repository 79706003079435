<script setup lang="ts">
interface Props {
  theme?: ButtonThemes
  size?: ButtonSizes
  title?: string
}

const {
  theme = BUTTON_THEME.TERTIARY,
  size = BUTTON_SIZE.BASE,
  title,
} = defineProps<Props>()

const { t } = useI18n()
const { icons } = useDesign()

defineOptions({
  name: 'ButtonClose',
})
</script>

<template>
  <KButton :theme :size :title="title ?? t('btn.close')" :icon="icons.CLOSE" />
</template>

<i18n>
de:
  btn:
    close: "Schließen"
es:
  btn:
    close: "Cerrar"
en:
  btn:
    close: "Close"
</i18n>

import { PAYMENT_METHOD } from '@base/constants/payment'
import type { Order } from '#gql/default'

export default function getTotalPriceFromOrder(order: Order) {
  switch (order.payment) {
    case PAYMENT_METHOD.CUSTOMER_CARD:
      return order.spentCardMoney ?? 0
    default:
      if (order.priceCompositions && order.priceCompositions.length > 0) {
        return order.priceCompositions.reduce(
          (acc, curr) => acc + curr.price.total,
          0
        )
      }

      return order.price?.total ?? 0
  }
}

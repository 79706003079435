<script setup lang="ts">
import type { OrderOverview } from '#gql/default'

interface Props {
  ticket: any
  overview: OrderOverview
  isFreeSeat?: boolean
}

const { ticket, isFreeSeat = false } = defineProps<Props>()

const { t } = useI18n()

const seatRowString = computed(() => {
  if (isFreeSeat) {
    return t('freeSeat')
  }

  if (ticket.seat) {
    return `${t('row')} ${ticket.seat.row}, ${t('seat')} ${ticket.seat.number}`
  }

  return undefined
})

defineOptions({
  name: 'OrderConfirmationCodesTicket',
})
</script>

<template>
  <div class="flex flex-col items-start print:text-black print:dark:text-black">
    <div
      v-if="ticket.codes?.[0]?.image"
      class="flex flex-col items-start space-y-1.5"
    >
      <OrderConfirmationTicketCodes :codes="ticket.codes" />

      <NuxtLink
        v-if="ticket.walletLinks?.pkpass"
        :to="ticket.walletLinks.pkpass"
      >
        <IconAppleWallet />
      </NuxtLink>
      <NuxtLink v-if="ticket.walletLinks?.gpass" :to="ticket.walletLinks.gpass">
        <IconGoogleWallet />
      </NuxtLink>
    </div>

    <div v-if="seatRowString" class="mt-1.5">
      <span class="font-bold" v-text="`${t('seatPlace')}: `" />
      <span
        class="text-primary font-normal print:text-black print:text-black"
        v-text="seatRowString"
      />
    </div>

    <div v-if="ticket.ticketNumbers">
      <span class="font-bold" v-text="`${t('ticketNumber')}: `" />
      <span v-text="ticket.ticketNumbers[0]" />
    </div>

    <span v-text="ticket.priceName" />
  </div>
</template>

<i18n>
de:
  yourTicketCode: "Dein Ticket-Code"
  seat: "Sitz"
  seatPlace: "Sitzplatz"
  ticketNumber: "TicketNr."
  row: "Reihe"
  freeSeat: "Freie Sitzauswahl"
es:
  yourTicketCode: "Tu código de ticket"
  seat: "Butaca"
  seatPlace: "Butaca"
  ticketNumber: "Código de recogida"
  row: "Fila"
  freeSeat: "Selección de butaca libre"
en:
  yourTicketCode: "Your ticket code"
  seat: "Seat"
  seatPlace: "Seat"
  ticketNumber: "Ticket number"
  row: "Row"
  freeSeat: "Free seat selection"
</i18n>

import type { Order, OrderConcession, OrderTicket } from '#gql/default'

export default function hasOrderCodes(order: Order) {
  if (
    (order.singleTicketCodes?.length ?? 0) > 0 ||
    order.shopItems?.some((item: OrderConcession) => item.codes) ||
    order.concessions?.some((item: OrderConcession) => item.codes) ||
    order.tickets?.some((item: OrderTicket) => item.codes)
  ) {
    return true
  }

  return false
}

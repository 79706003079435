<script setup lang="ts">
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE } from '@kh-ui/constants/button'

interface Props {
  type?: ButtonTypes
  theme?: ButtonThemes
  size?: ButtonSizes
  disabled?: boolean
  loading?: boolean
  truncate?: boolean
  badge?: string
  text?: string
  icon?: string
  suffixIcon?: string
}

const props = withDefaults(defineProps<Props>(), {
  type: BUTTON_TYPE.BUTTON,
  theme: BUTTON_THEME.PRIMARY,
  size: BUTTON_SIZE.BASE,
  disabled: false,
  truncate: true,
  text: '',
  badge: '',
  icon: undefined,
  suffixIcon: undefined,
  loading: false,
})

const { icons } = useDesign()

const hasPrefix = computed(
  () => !!(props.icon || props.loading) && !!props.text,
)
const hasSuffix = computed(
  () => (!!props.suffixIcon || !!props.badge) && !!props.text,
)

const { PRIMARY, SECONDARY, TERTIARY } = BUTTON_THEME

const iconClassList = computed(() => ({
  '!size-5': props.loading,
}))

defineOptions({
  name: 'KButton',
})
</script>

<template>
  <button
    :type="type"
    :disabled="disabled || loading"
    :data-prefix="hasPrefix === true ? true : undefined"
    :data-suffix="hasSuffix === true ? true : undefined"
    :data-icon="icon && !text ? true : undefined"
    class="button"
    :class="{
      'button-primary': theme === PRIMARY,
      'button-secondary': theme === SECONDARY,
      'button-tertiary': theme === TERTIARY,
      'button-xs': size === BUTTON_SIZE.XS,
      'button-sm': size === BUTTON_SIZE.SM,
      'button-base': size === BUTTON_SIZE.BASE,
      'button-lg': size === BUTTON_SIZE.LG,
    }"
  >
    <slot>
      <Icon
        v-if="icon || loading"
        :name="loading ? icons.LOADING : icon"
        :class="iconClassList"
      />
      <span v-if="text" :class="{ truncate }" v-text="text" />
      <Icon v-if="suffixIcon" :name="suffixIcon" :class="iconClassList" />
      <Badge v-if="badge" class="text-xs" v-text="badge" />
    </slot>
  </button>
</template>

<script setup lang="ts">
import type { Order } from '#gql/default'
import { LINK_TARGET } from '@base/constants/link'

interface Props {
  order: Order
}

const { order } = defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()
const localeRoute = useLocaleRoute()
const { currentHost } = useCurrentUrl()
const { icons } = useDesign()

const showBookingLink = computed(() => {
  return localeRoute({
    name: ROUTE_NAMES.CINEMA_SHOW_SHOW_SLUG,
    params: {
      citySlug: order.cinema?.city.urlSlug,
      cinemaSlug: order.cinema?.urlSlug,
      showSlug: order.show?.urlSlug,
    },
  })
})

const tellAFriendLink = computed(() => {
  return order.overview?.tellAFriendLink && currentHost.value
    ? `${currentHost.value}/${order.overview.tellAFriendLink}`
    : null
})

defineOptions({
  name: 'OrderConfirmationShare',
})
</script>

<template>
  <section class="mt-11 flex flex-col print:hidden" v-if="showBookingLink">
    <h2
      class="heading text-balance print:!text-black print:dark:!text-black"
      v-html="ct('THANKYOU_TAF_INTRO', null, t('friendsShareTitle'))"
    />
    <Divider />

    <span
      class="mt-2"
      v-html="ct('THANKYOU_TAF_INFO', null, t('friendsShareText'))"
    />

    <div class="flex flex-row space-x-1">
      <SocialShare
        v-for="network in ['email', 'facebook', 'twitter', 'whatsapp']"
        :key="network"
        :url="tellAFriendLink"
        :network="network"
        class="dark:text-neutral-light-2 mt-8"
      >
        <template #icon>
          <Icon v-if="network === 'email'" :name="icons.MAIL" />
          <SvgSocialFacebook v-else-if="network === 'facebook'" />
          <SvgSocialTwitter v-else-if="network === 'twitter'" />
          <SvgSocialWhatsapp v-else-if="network === 'whatsapp'" />
        </template>
      </SocialShare>
    </div>

    <div class="mt-8 space-x-1.5" v-if="tellAFriendLink">
      <span v-text="t('bookMore')" />
      <NuxtLink
        :to="tellAFriendLink"
        class="hover:text-primary text-light underline"
        :target="LINK_TARGET.BLANK"
      >
        {{ tellAFriendLink }}
      </NuxtLink>
    </div>
  </section>
</template>

<i18n>
de:
  friendsShareTitle: "Gemeinsam ins Kino – Plätze nebeneinander sichern!"
  friendsShareText: "Über diesen Link können andere schnell ihre Tickets buchen und sich direkt einen Platz in der Nähe sichern."
  bookMore: "Link zur Vorstellung:"
es:
  friendsShareTitle: "¿Queréis ir en grupo al cine y tener vuestras butacas juntas?"
  friendsShareText: "Comparte este enlace con quien quieras, verán dónde te vas a sentar y podrán comprar sus butacas cerca de la tuya."
  bookMore: "Vínculo para ver la presentación"
en:
  friendsShareTitle: "Let's go to the cinema together and have your seats next to each other!"
  friendsShareText: "Share this link with others, they'll see where you're sitting and can buy their seats near yours."
  bookMore: "Link to the show"
</i18n>

<script setup lang="ts">
defineOptions({
  name: 'BackgroundBlur',
})

interface Props {
  withScrollLock?: boolean
  visible: boolean
  zIndex?: string
}

const {
  withScrollLock = false,
  visible = false,
  zIndex = 'z-20',
} = defineProps<Props>()

const { toggle } = useScrollLockWithScrollBar()

if (visible && withScrollLock) {
  toggle()
}

watch(
  () => visible,
  () => {
    if (withScrollLock) {
      toggle()
    }
  },
)
</script>

<template>
  <TransitionFade>
    <div
      v-if="visible"
      class="fixed inset-0 bg-black/50 backdrop-blur-[2px]"
      :class="zIndex"
    />
  </TransitionFade>
</template>

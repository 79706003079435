import type { Order } from '#gql/default'

export default function isOrderCancelled(order: Order) {
  return (
    order.status === 'CANCELLED' ||
    order.status === 'REFUNDED_WITH_VOUCHER' ||
    order.status === 'REFUNDED_CASH' ||
    order.status === 'REFUNDED_CUSTOMERCARD' ||
    order.status === 'REFUNDED_FULL'
  )
}

<script setup lang="ts">
import type { LinkParam, Movie } from '#gql/default'
import { useParentElement } from '@vueuse/core'

interface Props {
  movie: Movie
  hideLink?: boolean
  renderAboveTheFold?: boolean
  linkParams: LinkParam[]
}

const {
  linkParams = [],
  hideLink = true,
  renderAboveTheFold = false,
} = defineProps<Props>()

const emit = defineEmits(['re-add', 'remove'])
const { icons } = useDesign()
const target = ref<HTMLElement | null>(null)
const recentlyRemovedFromWatchList = ref(false)

watch(recentlyRemovedFromWatchList, () => {
  const div = useParentElement(target)?.value?.querySelector('div')

  if (div) {
    div.classList.toggle('rounded-lg')
    div.classList.toggle('bg-gray-500')
    div.classList.toggle('opacity-50')
  }
})

const watchListIcon = computed(() =>
  recentlyRemovedFromWatchList.value
    ? icons.value.BOOKMARK_OUTLINE
    : icons.value.BOOKMARK_SOLID,
)

function handleWatchListClick() {
  if (recentlyRemovedFromWatchList.value) {
    recentlyRemovedFromWatchList.value = false
    emit('re-add')
  } else {
    recentlyRemovedFromWatchList.value = true
    emit('remove')
  }
}

defineOptions({
  name: 'WatchListMovieCard',
})
</script>

<template>
  <MovieCard
    :movie
    :hide-link
    :render-above-the-fold
    :link-params
    class="relative"
  >
    <button
      ref="target"
      class="absolute right-2 top-2 z-20 flex size-9 items-center justify-center rounded-full bg-black/50"
      @click.stop.prevent="handleWatchListClick"
    >
      <Icon
        :key="watchListIcon"
        class="fill-primary size-5"
        :name="watchListIcon"
      />
    </button>
  </MovieCard>
</template>

<script lang="ts" setup>
interface Props {
  image: string
  code: string
  size?: 'sm' | 'md' | 'lg'
}

const { size = 'md' } = defineProps<Props>()

const { t } = useI18n()

const heightClass = computed(() => {
  return {
    'h-16': size === 'sm',
    'h-24': size === 'md',
    'h-32': size === 'lg',
  }
})

defineOptions({
  name: 'BarCode',
})
</script>

<template>
  <NuxtImg
    :src="image"
    :alt="t('altText')"
    class="bg-light mr-auto aspect-auto"
    :class="heightClass"
  />
</template>

<i18n>
de:
  altText: "Bar-Code"
en:
  altText: "Bar-Code"
es:
  altText: "Código de barras"
</i18n>

<template>
  <div class="border-neutral flex flex-col space-y-4 border-t py-8">
    <span v-text="t('checkSpam')" />

    <KButton
      :text="t('contactSupport')"
      :theme="BUTTON_THEME.SECONDARY"
      @click="changeState(USER_AUTH_STATE.REGISTER)"
    />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { changeState } = useAuthState()

defineOptions({
  name: 'AuthFooterCheckSpam',
})
</script>

<i18n>
de:
  checkSpam: "Wenn du keine E-Mail von uns findest, überprüfe bitte deinen Spam-Ordner. Bei weiteren Problemen kannst du dich jederzeit an unseren Support werden."
  contactSupport: "Support kontaktieren"
es:
  checkSpam: "Si no encuentras un correo electrónico nuestro, por favor revisa tu carpeta de spam. Si tienes más problemas, no dudes en contactar a nuestro soporte."
  contactSupport: "Contactar soporte"
</i18n>

import type { Flag, Order } from '#gql/default'

export default async function useOrderCinemaStringsFetch(order: Order) {
  const { fetchCinemaStrings } = useCinemaStrings()

  if (order?.affiliate?.key) {
    useGqlHeaders({ 'x-kinoheld-affiliate': order.affiliate.key })
  }

  const ticketOrderFlagKeys = computed(() => {
    const temp: string[] = []

    if (order.show?.flags) {
      order.show.flags.forEach((flag: Flag) => {
        temp.push(`THANKYOU_BOOKINGNOTE_FLAG_${flag.name}`)
      })
    }

    return temp
  })

  const cinemaStringKeys = computed(() => {
    if (order.type === ORDER_TYPE.TICKET) {
      const keys = [
        'THANKYOU_H1',
        'THANKYOU_H2_1',
        'THANKYOU_H2_2',
        'THANKYOU_H3',
        'THANKYOU_SUBHEAD',
        'THANKYOU_TAF_INTRO',
        'THANKYOU_TAF_INFO',
        'THANKYOU_BOOKING_INFOS_JUSTREBOOKING',
        'THANKYOU_BOOKING_INFOS_CANCEL',
        'THANKYOU_BOOKINGNOTE',
        'MAIL_BOOKING_CUSTOMNOTICE',
        'ENTRADAS_NO_CONSENT_HTML_BLOCK',
        'MAIL_BOOKING_CONCESSIONS_INFO_SEATED',
        'MAIL_BOOKING_CONCESSIONS_INFO_NOT_SEATED',
      ]

      return keys.concat(ticketOrderFlagKeys.value)
    }

    if (order.type === ORDER_TYPE.SHOP) {
      return ['THANKYOU_SHOP_SUBHEAD']
    }

    return []
  })

  async function fetchOrderCinemaStrings() {
    if (order?.cinema?.id && cinemaStringKeys.value?.length) {
      await fetchCinemaStrings({
        cinemaId: order.cinema.id,
        keys: cinemaStringKeys.value,
      })
    }
  }

  await fetchOrderCinemaStrings()

  return {
    ticketOrderFlagKeys,
    cinemaStringKeys,
    fetchOrderCinemaStrings,
  }
}

import type { Cinema } from '#gql/default'

export function getFormattedCinemaAddress(cinema: Cinema) {
  return [cinema?.street, getFormattedCity(cinema)].filter(Boolean).join(', ')
}

export function getFormattedCity(cinema: Cinema) {
  return cinema?.postcode?.postcode
    ? `${cinema.postcode.postcode} ${cinema.city.name}`
    : cinema?.city?.name
}

<script setup lang="ts">
import type { PurchaseData } from '@account/types/order'
import type { Order } from '#gql/default'

interface Props {
  order: Order
  purchaseData: PurchaseData
}

defineProps<Props>()

const { t } = useI18n()
const { ct } = useCinemaStrings()

const { isWidgetMode } = useWidgetMode()

defineOptions({
  name: 'OrderConfirmationTypeTicket',
})
</script>

<template>
  <div>
    <OrderConfirmationHeading :order />
    <OrderConfirmationShare v-if="order?.show?.isBookableOrReservable" :order />
    <OrderConfirmationOrderOverviewTicket :order :purchase-data />
    <OrderConfirmationCodes :order />
    <OrderConfirmationConcessions :order />
    <Divider class="mb-2" />
    <OrderConfirmationPersonalization :order class="my-2" />
    <OrderConfirmationCancellation :order class="my-2" />
    <OrderConfirmationAgeRating :order />
    <OrderConfirmationCustomNotes :order />

    <div
      class="mt-12 font-bold print:!text-black print:dark:!text-black"
      v-html="ct('THANKYOU_H3', null, t('haveFunText'))"
    />

    <OrderConfirmationBackButton
      v-if="isWidgetMode"
      class="print:hidden"
      :cinema="order.cinema"
    />

    <RoktWidget
      v-if="order.roktIntegration"
      :attributes="order.roktIntegration"
    />
  </div>
</template>

<i18n>
de:
  haveFunText: "Viel Spaß beim Film."
es:
  haveFunText: "¡Disfruta de la película!"
en:
  haveFunText: "Have fun at your next cinema visit!"
</i18n>
